const state = {
  packageVersion: process.env.PACKAGE_VERSION || "0",
};

const getters = {
  appVersion: (state) => {
    return state.packageVersion;
  },
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
