<template>
  <div class="n3-badge">
    {{ label }}
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.n3-badge {
  position: relative;
  top: 30%;
  text-transform: none;
  border: 4px solid config("theme.colors.n3yellow");
  background-color: #f00;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: bold;
  display: inline-block;
}
</style>
