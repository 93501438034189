import Multiguard from "vue-router-multiguard";
import { useMiddleware } from "@/middleware/useMiddleware";

const { guest } = useMiddleware();

export default [
  {
    path: "/auth",
    name: "auth",
    beforeEnter: Multiguard([guest]),
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/auth/IndexPage.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/auth/SignIn.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/auth/SignUp.vue"),
      },
      {
        path: "reset",
        name: "reset",
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/auth/ResetPassword.vue"
          ),
      },
    ],
  },
];
