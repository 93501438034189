/* eslint-disable eqeqeq */
import { firebaseDB } from "@/services/Firebase";
import {
  collection,
  query,
  getDocs,
  Timestamp,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

const state = {
  event: {},
  events: [],
  fetchingData: false,
  dataLoaded: false,
  lastNumbers: [],
  showHeader: false,
};

const getters = {
  seats: (state) => state.event.seats,
};

const actions = {
  async fetchFromShows({ commit, state }) {
    state.fetchingData = true;
    let q = query(
      collection(firebaseDB, "screenings"),
      where("start", ">=", Timestamp.now()),
      orderBy("start"),
      where("maintenance", "==", false)
    );
    return new Promise((resolve, reject) => {
      getDocs(q)
        .then((snap) => {
          const v = [];
          snap.forEach((doc) => {
            const showData = doc.data();
            const exist = v.findIndex((e) => e.title === showData.movie.title);
            if (exist === -1) {
              v.push({
                ...{
                  eventId: showData.eventId,
                  date: showData.start.toDate(),
                  showsCount: 1,
                  tagOffer: showData.tagOffer || null,
                },
                ...showData.movie,
              });
            } else {
              v[exist].showsCount++;
            }
          });

          commit("setEvents", v);
          state.fetchingData = false;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async findEvent({ commit, state }, id) {
    state.fetchingData = true;
    let q = query(
      collection(firebaseDB, "screenings"),
      where("movie.slug", "==", id),
      where("start", ">=", Timestamp.now()),
      orderBy("start"),
      limit(1)
    );
    return new Promise((resolve, reject) => {
      getDocs(q)
        .then((snap) => {
          snap.forEach((doc) => {
            const showData = doc.data();
            commit("setEventFromShow", showData);
          });

          state.fetchingData = false;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

    // state.fetchingData = true;
    // const docRef = doc(firebaseDB, "events", id);
    // const docSnap = await getDoc(docRef);
    // if (docSnap.exists()) {
    //   commit("setEvent", docSnap.data());
    // } else {
    //   console.log("No such document!");
    // }
    // state.fetchingData = false;
  },

  assignFilter({ commit }, options) {
    return new Promise((resolve) => {
      commit("setFilter", options);
      resolve(true);
    });
  },
  addEvent({ commit }, event) {
    commit("newEvent", event);
  },

  attachEvent({ commit }, event) {
    commit("updateEvent", event);
  },

  assignEvent({ commit }, event) {
    commit("setEvent", event);
  },

  setState({ commit }, options) {
    return new Promise((resolve) => {
      commit("setFilter", options);
      resolve(true);
    });
  },
};

const mutations = {
  setEvents(state, events) {
    state.events = events;
  },

  setEventFromShow(state, show) {
    state.event = {
      ...show.movie,
      ...{
        tagOffer: show.tagOffer || null,
        date: show.start.toDate(),
        restrictions: show.restrictions,
      },
    };
  },

  updateEvent(state, event) {
    const index = state.events.findIndex((a) => a.id === event.id);
    state.events[index] = event;
    state.event = event;
  },

  setEvent(state, event) {
    state.event = event;
  },

  setFilter(state, options) {
    state[options.key] = options.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
