import { createApp } from "vue";
import VueGtag from "vue-gtag-next";
import { createMetaManager } from "vue-meta";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/custom.scss";
import "./assets/mobile.scss";

import axios from "axios";
import VueAxios from "vue-axios";
import "./registerServiceWorker";

import VueSweetAlert from "vue-sweetalert2";
import Vue3TouchEvents from "vue3-touch-events";

require("./bootstrap");

const app = createApp(App);

app.use(createMetaManager());
app.use(store);
app.use(router);

app.use(Vue3TouchEvents);

if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  app.use(VueGtag, {
    property: {
      id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    },
  });
}
app.use(VueSweetAlert);
app.use(VueAxios, axios);
await router.isReady();
app.mount("#app");
