import store from "@/store";
import { saveStorage } from "@/utils/helper";

import { isMobile } from "mobile-device-detect";

export const useMiddleware = () => {
  const mobile = (to, from, next) => {
    const pageUrl = "/download-our-app";
    if (isMobile && to.fullPath !== pageUrl) {
      next(pageUrl);
    } else if (!isMobile && to.fullPath === pageUrl) {
      next("/");
    } else {
      next();
    }
  };

  const auth = (to, from, next) => {
    const user = store.state.auth.currentUser;
    if (!user) {
      saveStorage("REDIRECT_TO", to.fullPath);
      next(`/auth/login`);
    } else {
      next();
    }
  };

  const guest = (to, from, next) => {
    const user = store.state.auth.currentUser;
    if (user) {
      next("/");
    } else {
      next();
    }
  };

  const existingReservation = (to, from, next) => {
    const reservation = store.state.reservation.reservation;
    if (
      reservation &&
      reservation.event &&
      to.params.showSlug !== reservation.event.slug
    ) {
      const url = `/${reservation.event.slug}/existing-reservation`;
      next(url);
    } else {
      next();
    }
  };

  const existingCheckout = (to, from, next) => {
    const stripe = store.state.reservation.stripeSession;
    const expireTime = store.getters["reservation/stripeExpireTime"];

    if (stripe && stripe.url && expireTime > 0) {
      const url = `/${to.params.showSlug}/existing-checkout`;
      next(url);
    } else if (stripe && stripe.url && expireTime <= 0) {
      store.dispatch("reservation/detach");
    } else {
      next();
    }
  };

  return {
    auth,
    guest,
    existingReservation,
    existingCheckout,
    mobile,
  };
};
