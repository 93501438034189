import Multiguard from "vue-router-multiguard";
import HomeView from "../views/HomeView.vue";
import { useMiddleware } from "@/middleware/useMiddleware";
const { mobile } = useMiddleware();

export default [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/download-our-app",
    name: "downloadApp",
    beforeEnter: Multiguard([mobile]),
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DownloadApp.vue"),
  },
];
