/* eslint-disable eqeqeq */
import { firebaseDB } from "@/services/Firebase";
import { getStorage, removeStorage, saveStorage } from "@/utils/helper";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";

const state = {
  reservationID: getStorage("RID") || null,
  showId: getStorage("SID") || null,
  stripeSession: getStorage("stripeSession") || null,
  reservation: {},
  reservations: [],
  selectedSeats: [],
  fetchingData: false,
  summaryComponent: "SignIn",
  reservationSnapshot: null,
};

const getters = {
  seats: (state) => state.reservation.seats,
  selectedSeats: (state) => state.selectedSeats,
  maxSeatReached: (state, getters, rootState) => {
    const max = rootState.show.show.maxTickets;
    const selected = state.selectedSeats.length;
    return max < selected + 1;
  },
  isExistReservation: (state) => {
    return state.reservation.state && state.reservation.state === "NEW";
  },
  expireTime: (state) => {
    let now = new Date();
    if (state.reservation && state.reservation.created) {
      now = state.reservation.created.toDate();
    }
    return new Date(now.getTime() + 1000 * 60 * 10);
    // now.setSeconds(now.getSeconds() + 10);
    // return now;
  },
  stripeExpireTime: (state) => {
    let timestamp = new Date();
    if (state.stripeSession && state.stripeSession.time) {
      timestamp = new Date(state.stripeSession.time);
    }
    timestamp.setMinutes(timestamp.getMinutes() + 45); // timestamp
    const diff = Date.parse(timestamp) - Date.parse(new Date());

    return diff;
  },
};

const actions = {
  async findReservation({ commit, state, rootState }) {
    console.log("Fetching reservation...", state.reservationID);
    const docRef = doc(
      firebaseDB,
      `screenings/${rootState.show.show.id}/reservations`,
      state.reservationID
    );
    const docSnap = await getDoc(docRef);
    return new Promise((resolve) => {
      if (docSnap.exists()) {
        commit("updateReservation", {
          ...{ id: docSnap.id },
          ...docSnap.data(),
        });
      }
      resolve(true);
    });
  },

  async findReservationWithSnapshot({ commit, state }) {
    state.fetchingData = true;
    console.log("Fetching reservation...", state.reservationID);
    return new Promise((resolve) => {
      state.reservationSnapshot = onSnapshot(
        doc(
          firebaseDB,
          `screenings/${state.showId}/reservations`,
          state.reservationID
        ),
        (doc) => {
          if (doc.exists()) {
            commit("updateReservation", { ...{ id: doc.id }, ...doc.data() });
            state.fetchingData = false;
            console.log("Reservation updated...");
          } else {
            commit("removeReservation");
          }
          resolve(true);
        }
      );
    });
  },

  async unsubscribeReservationSnapshot({ state }) {
    if (state.reservationSnapshot) {
      state.reservationSnapshot();
      state.reservation = null;
      console.log("Reservation unsubscribed");
    }
  },

  // async makeReservation({ rootState, commit }) {
  //   console.log("New reservation...");
  //   return new Promise((resolve) => {
  //     const reservationData = {
  //       status: "NEW",
  //       device: "WEB",
  //       email: "ajanth743@gmail.com",
  //       event: rootState.event.event,
  //       seats: [],
  //     };

  //     const dbRef = collection(
  //       firebaseDB,
  //       `screenings/${rootState.show.show.id}/reservations`
  //     );
  //     reservationData.createdAt = Timestamp.now();
  //     addDoc(dbRef, reservationData).then((docRef) => {
  //       saveStorage("RID", docRef.id);
  //       commit("setReservation", { ...{ id: docRef.id }, ...docRef.data() });
  //     });
  //     resolve(true);
  //   });
  // },

  async setStatus({ rootState }, payload) {
    const reservationDocRef = doc(
      firebaseDB,
      `screenings/${rootState.show.show.id}/reservations`,
      rootState.reservation.reservationID
    );
    return updateDoc(reservationDocRef, payload);
  },

  async setStripeSession({ commit }, payload) {
    commit("setState", { key: "stripeSession", value: payload });
    saveStorage("stripeSession", payload);
  },

  detach({ commit }) {
    commit("removeReservation");
  },

  setState({ commit }, options) {
    return new Promise((resolve) => {
      commit("setState", options);
      resolve(true);
    });
  },

  attachReservation({ commit }, reservation) {
    commit("updateReservation", reservation);
  },
};

const mutations = {
  setReservations(state, reservations) {
    state.reservations = reservations;
  },

  updateReservation(state, reservation) {
    state.reservation = reservation;
    state.selectedSeats = reservation.seats;
    state.reservationID = reservation.id;
    state.showId = reservation.event?.id;
  },
  updateSeat(state, seat) {
    const seatIndex = state.seats.findIndex((a) => a.id === seat.id);
    state.seats[seatIndex] = seat;
  },

  setReservation(state, reservation) {
    state.reservation = reservation;
  },

  setState(state, options) {
    state[options.key] = options.value;
  },

  removeReservation(state) {
    if (state.reservationSnapshot) {
      state.reservationSnapshot();
      console.log("Reservation listener unsubscribed");
    }
    state.reservationID = null;
    state.showId = null;
    state.reservation = {};
    state.selectedSeats = [];
    state.stripeSession = null;
    removeStorage("RID");
    removeStorage("SID");
    removeStorage("stripeSession");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
