import { createRouter, createWebHistory } from "vue-router";
import home from "./home";
import auth from "./auth";
import event from "./event";
import account from "./account";
import page from "./page";
// import middlewarePipeline from "./middlewarePipeline";

const routes = [...home, ...auth, ...event, ...account, ...page];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
