<template>
  <div class="main-header">
    <div class="container">
      <Header />
    </div>
  </div>
  <div class="relative">
    <n-3-carousel :timeout="5000" :autoplay="true" />
    <div class="card-top-overlay"></div>
  </div>

  <div class="container">
    <div class="pt-10 mb-10" v-if="events.length > 0">
      <div class="font-medium relative">
        <div class="flex">
          <span class="inline-block uppercase flex-1 main-title"> Events </span>
        </div>
      </div>
    </div>
    <div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-10">
        <template v-if="loading && !events.length">
          <skeleton-n-3-event-card v-for="row in 4" :key="row" />
        </template>
        <div v-for="(event, index) in events" :key="index">
          <n-3-event-card :event="event" />
        </div>
      </div>
    </div>

    <div class="mt-10 sm:mt-32">
      <div class="font-medium relative">
        <div class="flex">
          <span class="inline-block uppercase flex-1 main-title">
            What we do
          </span>
        </div>
      </div>
    </div>

    <div class="mt-5 sm:mt-10">
      <p class="text-content text-justify">
        N3, your ultimate ticketing solution, simplifies event discovery and
        booking. Offering a diverse range of global events, we streamline ticket
        purchase, making it effortless. Experience hassle-free payments,
        extensive event options, and secure QR-coded passes for seamless entry.
        Our user-friendly platform ensures swift event exploration, while
        various payment methods provide convenience. With N3, you gain access to
        exclusive events, early bookings, and personalized recommendations. We
        prioritize your satisfaction, assisting with inquiries through
        exceptional customer support. Elevate your event experience with N3,
        where simplicity meets choice, security, and unparalleled convenience.
        Your event, your way – effortlessly accessible through N3.
      </p>
    </div>
  </div>

  <div class="newsletter my-32" v-if="false">
    <div class="newsletter-blur">
      <img src="@/assets/background/newsletter.jpeg" alt="" />
      <span class="blurry"></span>
      <div class="overlay text-center">
        <div class="mt-10">
          <div class="uppercase text-[45px] font-medium">Don't miss out</div>
          <div class="text-[35px] mt-5 leading-10">
            <p>
              Stay in the Loop with N3! Subscribe to our newsletter for the
              latest updates on exciting events, exclusive offers, and more.
            </p>
            <p>
              Don't miss out – be the first to know. Join our community today!
            </p>
          </div>
          <div class="mt-10">
            <div class="flex">
              <div class="flex-1 mr-10">
                <input
                  type="text"
                  class="h-[70px] w-full text-black text-[30px] p-2"
                />
              </div>
              <div>
                <default-button label="Subscribe" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-view />
  <div class="my-10">
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import { computed, onMounted, reactive, toRefs } from "@vue/runtime-core";
import { getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useMeta } from "vue-meta";

import Footer from "@/components/Footer.vue";
import N3Carousel from "@/components/N3Carousel.vue";
import N3EventCard from "@/components/home/N3EventCard.vue";
import SkeletonN3EventCard from "@/components/home/SkeletonN3EventCard.vue";
import DefaultButton from "@/components/button/DefaultButton.vue";
export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
    N3Carousel,
    N3EventCard,
    SkeletonN3EventCard,
    DefaultButton,
  },
  setup() {
    useMeta({
      title: "",
      description:
        "N3, your ultimate ticketing solution, simplifies event discovery and booking. Offering a diverse range of global events, we streamline ticket purchase, making it effortless. Experience hassle-free payments, extensive event options, and secure QR-coded passes for seamless entry. Our user-friendly platform ensures swift event exploration, while various payment methods provide convenience. With N3, you gain access to exclusive events, early bookings, and personalized recommendations. We prioritize your satisfaction, assisting with inquiries through exceptional customer support. Elevate your event experience with N3, where simplicity meets choice, security, and unparalleled convenience. Your event, your way – effortlessly accessible through N3.",
    });
    const { proxy } = getCurrentInstance();
    const store = useStore();

    const state = reactive({
      loading: false,
      section: computed(() => proxy.$route.name || ""),
      event: computed(() => store.state.event.event),
      events: computed(() => store.state.event.events),
      fetchingEvent: computed(() => store.state.event.fetchingData),
      bannerHeight: computed(() => {
        let height = "n3-banner-min-height";
        if (state.section === "event") {
          height = "n3-banner-full-height";
        }

        return height;
      }),

      textHeight: computed(() => {
        let height = "n3-content-min-height";
        if (state.section === "event") {
          height = "n3-content-full-height";
        }

        return height;
      }),
    });

    onMounted(async () => {
      state.loading = true;
      await store.dispatch("event/fetchFromShows");
      state.loading = false;
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss">
// .banner-background {
//   background: black;
//   background-image: url("@/assets/img/banner.png");
//   color: #fff;
//   background-repeat: no-repeat;
//   background-size: 100% auto;
//   &.n3-banner-full-height {
//     height: 1080px;
//   }
//   &.n3-banner-min-height {
//     height: 588px;
//   }
//   .n3-content-full-height {
//     height: 900px;
//   }
//   .n3-content-min-height {
//     height: 450px;
//   }
// }
.expanded-bg {
  height: 100%;
  position: absolute;
  width: -webkit-fill-available;
  background-color: #000;
}
.before-transparent {
  content: "";
  width: 100%;
  height: 150px;
  position: absolute;
  background: linear-gradient(0deg, rgb(9 0 0 / 96%), transparent);
  z-index: 16;
  top: -150px;
}

.newsletter {
  .newsletter-blur {
    overflow: hidden;
    position: relative;
    + .newsletter-blur {
      margin-top: 20px;
    }
  }

  .blurry {
    background-image: url("@/assets/background/newsletter.jpeg");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
    content: "";
    filter: blur(18px);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 62%;
  }

  .overlay {
    // align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    color: white;
    display: flex;
    font-size: 20px;
    height: 100%;
    padding: 20px 120px;
    position: absolute;
    top: 0;
    right: 0;
    width: 62%;
    z-index: 1;

    .alt & {
      left: 0;
      right: auto;
    }

    .middle & {
      transform: translateX(-50%);
    }

    h2 {
      font-size: 36px;
      margin: 0;
    }
  }
}
</style>
