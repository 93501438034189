<template>
  <div class="show-card">
    <div
      class="image"
      :style="`background-image: url(&quot;${event.posterUrlLandscape}&quot;);`"
      @click.prevent="
        $router.push({
          name: 'eventDetail',
          params: { slug: event.slug },
        })
      "
    >
      <div class="card-bottom-overlay"></div>
      <div class="content">
        <div class="caption text-subtitle">
          {{ event.subtitle }}
          <N3Badge class="ml-2" :label="event.tagOffer" v-if="event.tagOffer" />
        </div>
        <div class="title text-title">{{ event.title }}</div>
        <div class="date text-subtitle">
          {{ event.showsCount > 1 ? "From" : "" }}
          {{ dateTimeFormat(event.date, "ddd Do MMM YYYY") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateTimeFormat } from "@/utils/helper";
import N3Badge from "../show/N3Badge.vue";

export default {
  components: { N3Badge },
  props: ["event"],
  setup() {
    return {
      dateTimeFormat,
    };
  },
};
</script>
<style lang="scss">
.show-card {
  position: relative;
  cursor: pointer;
  .content {
    margin-left: 2rem;
    position: absolute;
    bottom: 5%;
    z-index: 30;
    .caption {
    }
    .title {
      font-weight: 500;
      text-transform: uppercase;
    }
    .date {
      color: #858585;
    }
    .action {
      margin-top: 10px;
      font-weight: 500;
    }
  }
  .image {
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
  }
}
</style>
