export default [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "page" */ "../views/pages/IndexPage.vue"),
    children: [
      {
        path: "privacy",
        name: "pagePrivacy",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "../views/pages/PrivacyPolicy.vue"
          ),
      },
      {
        path: "terms",
        name: "pageTerms",
        component: () =>
          import(
            /* webpackChunkName: "page" */ "../views/pages/TermsAndConditions.vue"
          ),
      },
      {
        path: ":catchAll(.*)*",
        name: "404",
        authRequired: true,
        component: () =>
          import(/* webpackChunkName: "event" */ "../views/errors/404.vue"),
      },
    ],
  },
];
