<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `${content} |  N3` : "N3" }}
    </template>
    <template v-slot:description="{ content }">
      {{ content }}
    </template>

    <template v-slot:meta="{ content }"> {{ content }} </template>
  </metainfo>
  <router-view />
  <timer />
</template>
<script>
import Timer from "./components/Timer.vue";
import { useMeta } from "vue-meta";
export default {
  components: { Timer },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "en", amp: true },
      link: [
        {
          rel: "canonical",
          href: location.href,
        },
      ],
    });
  },
};
</script>
<style>
@import "https://use.typekit.net/qmy5ufi.css";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
</style>
<style lang="scss">
@import "@sweetalert2/theme-dark/dark.scss";
</style>
