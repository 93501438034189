import Multiguard from "vue-router-multiguard";
import { useMiddleware } from "@/middleware/useMiddleware";

const { auth, existingCheckout, existingReservation } = useMiddleware();

export default [
  {
    path: "/city/:city",
    name: "city",
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/event/CityPage.vue"),
  },

  {
    path: "/city/:city/:title",
    name: "cityShow",
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/event/CityShowPage.vue"),
  },
  {
    path: "/",
    name: "events",
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/event/IndexPage.vue"),
    children: [
      {
        path: "event/:slug?",
        name: "eventDetail",
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/DetailPage.vue"
          ),
      },
      {
        path: ":showSlug/seat",
        name: "seatSelection",
        beforeEnter: Multiguard([existingCheckout, existingReservation]),
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/SeatSelection.vue"
          ),
      },
      {
        path: ":showSlug/standing",
        name: "standingSelection",
        beforeEnter: Multiguard([existingCheckout, existingReservation]),
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/StandSelection.vue"
          ),
      },
      {
        path: ":showSlug/summary",
        name: "reservationSummary",
        beforeEnter: Multiguard([auth, existingCheckout]),
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/EventSummaryPage.vue"
          ),
      },
      {
        path: ":showSlug/payment",
        name: "paymentSummary",
        beforeEnter: Multiguard([auth]),
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/payment/Index.vue"
          ),
      },
      {
        path: ":showSlug/existing-checkout",
        name: "existCheckout",
        beforeEnter: Multiguard([auth]),
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/ExistCheckout.vue"
          ),
      },
      {
        path: ":showSlug/existing-reservation",
        name: "existReservation",
        beforeEnter: Multiguard([auth]),
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/ExistReservation.vue"
          ),
      },
    ],
  },
];
