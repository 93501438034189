import store from "./store";
import { isMobile } from "mobile-device-detect";

if (
  !store.state.auth.snapshot &&
  store.state.auth.currentUser &&
  store.state.auth.currentUser.uid
) {
  store.dispatch("auth/initUserSnapshot", store.state.auth.currentUser.uid);
}

if (store.state.reservation.reservationID) {
  store.dispatch("reservation/findReservationWithSnapshot");
}

if (isMobile) {
  document.body.classList.add("mobile");
} else {
  document.body.classList.remove("mobile");
}
