import Multiguard from "vue-router-multiguard";
import { useMiddleware } from "@/middleware/useMiddleware";

const { auth } = useMiddleware();

export default [
  {
    path: "/account",
    // meta: { middleware: [auth] },
    beforeEnter: Multiguard([auth]),
    component: () =>
      import(
        /* webpackChunkName: "account" */ "../views/account/IndexPage.vue"
      ),
    children: [
      {
        path: "",
        name: "accountHome",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/Profile.vue"
          ),
      },
      {
        path: "tickets",
        name: "accountTicket",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/ticket/Index.vue"
          ),
      },
      {
        path: "ticket",
        name: "accountTicketDetail",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/ticket/Detail.vue"
          ),
      },

      {
        path: "password",
        name: "accountPassword",
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/Password.vue"
          ),
      },
    ],
  },
];
