const SeatStatus = {
  FREE: "FREE",
  PAID: "PAID",
};

const DateFormat = {
  STANDARD: "DD/MM/yyyy HH:mm:ss",
  DISPLAY: "DD.MM.YYYY HH:mm:",
};

const ReservationStatus = {
  NEW: "NEW",
  PENDING: "PENDING",
  PAID: "PAID",
  FAILED: "FAILED",
  CHECKOUT: "CHECKOUT",
};

const PaymentStatusComponent = {
  FAILED: "Fail",
  SUCCESS: "Success",
};

const SeatingType = {
  STANDING: "STANDING",
  SEATING: "SEATING",
};

const AccountBookingComponent = {
  PAST_BOOKING: "PastBooking",
  UPCOMING_BOOKING: "UpcomingBooking",
};

export {
  DateFormat,
  SeatingType,
  PaymentStatusComponent,
  AccountBookingComponent,
  ReservationStatus,
  SeatStatus,
};
