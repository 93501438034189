<template>
  <div
    class="logo text-center inline-block cursor-pointer"
    @click="link ? $router.push({ name: 'home' }) : ''"
  >
    <img src="/img/logo-square.png" alt="" />
  </div>
</template>
<script>
export default {
  props: {
    link: {
      type: Boolean,
      default: true,
    },
  },
  name: "LogoBlack",
  setup() {},
};
</script>
<style lang="scss">
.logo {
  width: 80px;
  height: 80px;
  opacity: 1;
  font-size: 3rem;
  color: #fff;
}
.mobile {
  .logo {
    width: 60px;
    height: 60px;
  }
}
</style>
