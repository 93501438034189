<template>
  <div
    class="n3-carousal"
    :class="{ 'no-events': sliders.length === 0 && !fetchingEvent }"
  >
    <ul class="carousel-list">
      <li
        class="carousel-item active"
        v-if="sliders.length === 0 && !fetchingEvent"
      >
        <div>
          <video
            id="video-container"
            autoplay
            src="https://swiperjs.com/images/videos/carousel-dark.mp4"
            loop
            style="width: 100%"
          ></video>
        </div>
      </li>

      <li
        class="carousel-item"
        :class="{ 'active fade-in': activeIndex === index }"
        v-for="(item, index) in sliders"
        :key="index"
      >
        <div>
          <div
            class="image"
            :style="`background-image: url(&quot;${getBanner(item)}&quot);`"
          ></div>
          <div class="container">
            <div class="slider-content">
              <div class="main-subtitle">
                {{ item.subtitle
                }}<n-3-badge
                  :label="item.tagOffer"
                  class="ml-5"
                  v-if="item.tagOffer"
                />
              </div>
              <div class="main-title truncate relative">
                {{ item.title }}
              </div>
              <div class="text-subtitle">
                {{ item.showsCount > 1 ? "From" : "" }}
                {{ dateTimeFormat(item.date, "ddd Do MMM YYYY") }}
              </div>

              <div class="mt-5">
                <default-button
                  label="More info"
                  classes="w-full sm:w-[300px]"
                  @click="
                    $router.push({
                      name: 'eventDetail',
                      params: { slug: item.slug },
                    })
                  "
                />
                <div class="float-right" v-if="sliders.length > 1">
                  <div class="slider-nav mt-10 sm:mt-0">
                    <div class="slider-nav-item-left" @click="previous()">
                      <img src="@/assets/icons/arrow-left.png" alt="" />
                    </div>
                    <div class="slider-nav-item-right" @click="next()">
                      <img src="@/assets/icons/arrow-right.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="slider-overlay"></div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import DefaultButton from "./button/DefaultButton.vue";
import { useStore } from "vuex";
import { dateTimeFormat } from "@/utils/helper";
import N3Badge from "./show/N3Badge.vue";
import { isMobile } from "mobile-device-detect";
export default {
  components: { DefaultButton, N3Badge },
  name: "N3Carousel",
  props: {
    timeout: {
      type: Number,
      default: 3000,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    let activeIndex = ref(0);
    let images = ref([]);
    let interval = null;

    const state = reactive({
      videoPlayer: null,
      events: computed(() => store.state.event.events),
      fetchingEvent: computed(() => store.state.event.fetchingData),
      sliders: computed(() => {
        return state.events;
      }),
    });

    onMounted(() => {
      if (!interval) {
        startAutoplay();
      }
    });

    watch(
      () => state.sliders.length,
      (v) => {
        if (v > 0) {
          startAutoplay();
        }
      }
    );

    onUnmounted(() => {
      clearTimer();
    });

    function getBanner(item) {
      return isMobile ? item.posterUrlSquare : item.posterUrlLandscape;
    }

    function clearTimer() {
      if (interval && props.autoplay) clearInterval(interval);
    }

    function startAutoplay() {
      if (props.autoplay) {
        interval = setInterval(() => {
          next();
        }, props.timeout);
      }
    }

    function next() {
      clearTimer();
      const total = state.sliders.length;
      if (!total) return;
      if (total - 1 === activeIndex.value) {
        activeIndex.value = 0;
      } else {
        activeIndex.value++;
      }
      startAutoplay();
    }

    function previous() {
      clearTimer();
      const total = state.sliders.length;
      if (activeIndex.value === 0) {
        activeIndex.value = total - 1;
      } else {
        activeIndex.value--;
      }
      startAutoplay();
    }

    return {
      ...toRefs(state),
      dateTimeFormat,
      activeIndex,
      images,
      next,
      previous,
      getBanner,
    };
  },
};
</script>
<style lang="scss">
.n3-carousal {
  // position: relative;
  height: 100vh;
  &.no-events {
    height: auto;
  }
  ul.carousel-list {
    .carousel-item {
      display: none;
      &.active {
        display: block;
      }
      .image {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      img {
        width: 100%;
      }

      .slider-content {
        width: 100%;
        margin-top: -400px;
        position: relative;
        z-index: 999;
      }
      .slider-overlay {
        content: "";
        height: 700px;
        background: linear-gradient(0deg, rgb(9, 0, 0), transparent);
        position: absolute;
        z-index: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
  .slider-nav {
    display: flex;
    .slider-nav-item-left,
    .slider-nav-item-right {
      cursor: pointer;
    }
    .slider-nav-item-left {
      margin-right: 2.5rem;
    }
    .slider-nav-item-right {
      margin-left: 2.5rem;
    }
  }
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
