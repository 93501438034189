<template>
  <div class="bg-black pt-10">
    <div class="container">
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-6 footer-nav"
      >
        <div class="">
          <img
            src="/img/logo-square.png"
            alt=""
            class="w-[60px] sm:w-[100px]"
          />
        </div>
        <div class="">
          <div class="title">Your Events</div>
          <ul>
            <li>
              <a
                href=""
                @click.prevent="$router.push({ name: 'accountTicket' })"
                >Your Ticket</a
              >
            </li>
            <li>
              <a href="" @click.prevent="$router.push({ name: 'accountHome' })"
                >Your Account</a
              >
            </li>
          </ul>
        </div>

        <div class="">
          <div class="title">Others</div>
          <ul>
            <li>
              <a
                href="/privacy"
                @click.prevent="$router.push({ name: 'pagePrivacy' })"
                target="_blank"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                href="/terms"
                @click.prevent="$router.push({ name: 'pageTerms' })"
                target="_blank"
                >Term & Conditions</a
              >
            </li>
          </ul>
        </div>

        <div class="">
          <div class="title">About</div>
          <ul>
            <li><a href="mailto:support@n3.app">Contact Us</a></li>
            <li><a href="mailto:support@n3.app">Imprint</a></li>
          </ul>
        </div>

        <div class="">
          <div class="title">Any Questions?</div>
          <a href="mailto:support@n3.app" class="text-n3yellow text-content"
            >support@n3.app</a
          >
        </div>

        <div class="">
          <div class="title">Download our App</div>

          <div class="flex">
            <div class="flex-1">
              <a href="https://n3.app/ios" target="_blank" class="mr-2">
                <img src="/img/app-store.svg" alt="" class="w-[100px]" />
              </a>
              <a href="https://n3.app/android" target="_blank">
                <img src="/img/google-play.svg" alt="" class="w-[100px]" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="n3-footer-copyright">
      Copyright &copy; {{ currentDateTime("YYYY") }} N3 | v{{
        $store.getters["home/appVersion"]
      }}
    </div>
  </div>
</template>
<script>
import { currentDateTime } from "@/utils/helper";
export default {
  name: "FooterPage",
  setup() {
    return {
      currentDateTime,
    };
  },
};
</script>
<style lang="scss">
.n3-icon {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-size: 30px;
  background-image: url("@/assets/img/social-media.png");
  &.facebook {
    background-position: 0 0;
  }
  &.instagram {
    background-position-y: -66px;
  }
  &.twitter {
    background-position-y: -130px;
  }
}
.footer-nav {
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  .title {
    text-transform: uppercase;
    font-weight: 700;
    color: #7c7c7c;
    margin-bottom: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    li a {
      color: #fff;
    }
  }
}
.n3-footer-copyright {
  text-align: center;
  color: #7c7c7c;
  padding: 20px;
}
</style>
